<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_danalock_connect')"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
        hide-footer
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <b-form>
                        <b-form-group
                            :label="$t('providers.danalock_username')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-input
                                v-model="$v.form.Username.$model"
                                :state="validateState('Username')"
                                :autocomplete="'new-password'"
                                id="danalockName"
                                :placeholder="$t('providers.danalock_username')"
                            />
                            <b-form-invalid-feedback id="danalockName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_email") }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('providers.danalock_password')"
                            label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm"
                        >
                            <b-form-input
                                v-model="$v.form.Password.$model"
                                :state="validateState('Password')"
                                type="password"
                                :autocomplete="'new-password'"
                                id="danalockPassword"
                                :placeholder="$t('providers.danalock_password')"
                            />
                            <b-form-invalid-feedback id="danalockPassword-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_password") }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label-cols-sm="2" label-size="sm">
                            <b-button
                                class="input-field col-6 danalock-connect"
                                @click.once="loginDanalock"
                                :disabled="registering"
                                ><span v-if="!connecting">
                                    {{ $t("buttons.danalock_connect") }}
                                </span>
                                <b-spinner small v-else></b-spinner
                            ></b-button>
                            <b-button
                                class="input-field col-6 danalock-register"
                                @click.once="registerDanalock"
                                :disabled="connecting"
                                ><span v-if="!registering">
                                    {{ $t("buttons.danalock_register") }}
                                </span>
                                <b-spinner small v-else></b-spinner
                            ></b-button>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
    CONNECT_DANALOCK,
    LOGIN_DANALOCK,
    REGISTER_DANALOCK,
} from "@/store/types/providers";
import { M_DANALOCK_LOGIN } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
const stringEntropy = require("fast-password-entropy");

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_DANALOCK_LOGIN,
            user: null,
            form: { Username: null, Password: null },
            submitted: false,
            connecting: false,
            registering: false,
            mode: null,
        };
    },
    validations: {
        form: {
            Username: {
                required,
                email,
            },
            Password: {
                required,
                entropy: function () {
                    if (this.mode == "login") {
                        return this.form.Password != "";
                    }
                    return stringEntropy(this.form.Password) >= 64;
                },
            },
        },
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty && this.submitted ? !$error : null;
        },

        loginDanalock() {
            this.submitted = true;
            this.mode = "login";

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.connecting = true;
            this.form.userId = this.user.Id;
            this.$store
                .dispatch(LOGIN_DANALOCK, this.form)
                .then((result) => {
                    this.connecting = false;
                    if (result) {
                        this.$emit("connected");
                        this.$bvModal.hide(this.type);
                    }
                })
                .catch((error) => {});
        },

        registerDanalock() {
            this.submitted = true;
            this.mode = "register";

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.registering = true;
            this.form.userId = this.user.Id;
            this.$store
                .dispatch(REGISTER_DANALOCK, this.form)
                .then((result) => {
                    this.registering = false;
                    if (result) {
                        this.$emit("connected");
                        this.$bvModal.hide(this.type);
                    }
                })
                .catch((error) => {});
        },
        setData() {
            this.user = { ...this.getModalData.user };
        },
        onClose() {
            this.user = null;
            this.$v.form.$reset();
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
<style scoped>
.danalock-register,
.danalock-connect {
    width: 48%;
}
.danalock-register {
    float: right;
}
</style>