<template>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
            <!-- Header -->
            <div class="header">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <!-- Pretitle -->
                                <h6 class="header-pretitle">
                                    {{ $t("users.profile") }}
                                </h6>

                                <!-- Title -->
                                <h1 class="header-title">
                                    {{ $t("users.settings") }}
                                </h1>
                            </div>
                        </div>
                        <!-- / .row -->
                        <div class="row align-items-center">
                            <div class="col">
                                <!-- Nav -->
                                <b-nav tabs class="nav nav-tabs nav-overflow header-tabs">
                                    <b-nav-item :active="activeTab == 0" @click="activeTab = 0">
                                        {{ $t("users.settings_general") }}
                                    </b-nav-item>
                                    <b-nav-item :active="activeTab == 1" @click="activeTab = 1">
                                        {{ $t("companies.company") }}
                                    </b-nav-item>
                                    <b-nav-item :active="activeTab == 2" @click="activeTab = 2">
                                        {{ $t("permissions.title") }}
                                    </b-nav-item>
                                    <b-nav-item :active="activeTab == 3" @click="activeTab = 3">
                                        {{ $t("menu.providers") }}
                                    </b-nav-item>
                                    <b-nav-item :to="{ name: 'developers' }">
                                        {{ $t("menu.developers") }}
                                    </b-nav-item>
                                </b-nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <b-form v-show="activeTab == 0">
                    <div class="row justify-content-between align-items-center" >
                        <div class="col">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <!-- Avatar -->
                                    <div class="avatar avatar-xl" v-if="!avatar">
                                        <b-avatar
                                            class="avatar-img rounded-circle"
                                            style="background-color: transparent !important;"
                                            :src="user.MediumImageUrl || '/images/person-big_MyZesec_140dp.png'"
                                        ></b-avatar>
                                    </div>
                                </div>
                                <div class="col ml-n2">
                                    <!-- Heading -->
                                    <h4 class="mb-1">
                                        {{ $t("users.avatar") }}
                                    </h4>

                                    <!-- Text -->
                                    <!-- <small class="text-muted">
                                        {{ $t("users.avatar_label") }}
                                    </small>  -->
                                   <small class="text-muted" style="font-size: -0.1875rem !important;">
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_1") }}</p>
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_2") }}</p>
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_3") }}</p>
                                        <p style="margin-bottom: -5px;">{{ $t("users.avatar_label_4") }}</p>
                                    </small>
                                </div>
                            </div>
                            <!-- / .row -->
                        </div>
                        <div class="col-auto">
                            <!-- Button -->
                            <button
                                class="btn btn-sm btn-primary"
                                @click.stop.prevent="userImage"
                            >
                                {{ $t("common.image_select") }}
                            </button>
                        </div>
                    </div>
                    <hr class="my-5" />
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <!-- First name -->
                            <b-form-group :label="$t('users.first_name')">
                                <b-form-input
                                    autocomplete="stop"
                                    v-model="$v.user.FirstName.$model"
                                    :state="validateState('FirstName')"
                                    id="userFirstName"
                                    :placeholder="$t('users.first_name')"
                                />
                                <b-form-invalid-feedback
                                    id="userFirstName-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "3-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Last name -->
                            <b-form-group :label="$t('users.last_name')">
                                <b-form-input
                                    autocomplete="stop"
                                    v-model="$v.user.LastName.$model"
                                    :state="validateState('LastName')"
                                    id="userLastName"
                                    :placeholder="$t('users.last_name')"
                                />
                                <b-form-invalid-feedback
                                    id="userLastName-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_range", {
                                            range: "3-30",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <!-- Email address -->
                            <b-form-group label="Email">
                                <b-form-input
                                    autocomplete="stop"
                                    v-model="$v.user.Email.$model"
                                    :state="validateState('Email')"
                                    id="userEmail"
                                    placeholder="Email"
                                    disabled
                                />
                                <b-form-invalid-feedback id="userEmail-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_email")
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <b-form-group :label="$t('users.phone')">
                                <vue-phone-number-input
                                    v-model="$v.user.PhoneNumber.$model"
                                    id="userPhone"
                                    :translations="$t('phoneInput')"
                                    :error="!validPhone && submitted"
                                    @update="validatePhone"
                                    :default-country-code="user.countryCode"
                                    required
                                    disabled
                                    type="text"
                                />
                                <span id="userPhone-feedback"  v-if="!validPhone && submitted"  style="color: #e63857;  font-size: 0.8125rem">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("errors.validation_required") }}
                                </span>
                            </b-form-group>
                        </div>
                    </div>
                    <hr class="my-5" />
                    <b-button
                        @click="updateProfile"
                        variant="primary"
                        class="mb-3"
                    >{{ $t("users.update") }}</b-button
                    >
                </b-form>
                <div class="card" v-show="activeTab == 1">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="col-auto mr-n3">
                            <Select2
                            v-model="perPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="paginatedList"
                            />
                        </form>
                        <b-button
                            variant="primary"
                            size="sm"
                            @click.stop.prevent="createCompany"
                        >{{ $t("companies.create") }}</b-button
                        >
                    </div>
                    <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filterCompany"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                    </form>
                    </div>
                    <div class="card-body">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="userCompanies.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="false">
                            <template v-slot:content>
                               <!--  v-for="(company, index) in getUserCompanies"
                               v-for="(company, index) in userCompanies" -->
                                <user-company-item
                                    v-for="(company, index) in paginatedList"
                                    :company="company"
                                    v-bind:key="index"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('companies.no_companies')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="userCompanies.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                </div>
                <div class="card" v-show="activeTab == 2">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="col-auto mr-n3">
                                <Select2
                                v-model="perPermissionPage"
                                :options="perPageOptions"
                                :settings="{
                                    minimumResultsForSearch: -1,
                                    containerCssClass:
                                            'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                            'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                                }"
                                @change="paginatedPermissionList"
                                />
                        </form>
                    </div>
                    <!-- <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filterPermission"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                    </form>
                    </div> -->
                    <div class="card-body">
                        <b-pagination
                            v-model="currentPermissionPage"
                            :total-rows="getUserRolesFiltered.length"
                            :per-page="perPermissionPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showRolesLoader">
                            <template v-slot:content>
                               <!--  v-for="(role, index) in getUserRolesFiltered" -->
                                <user-role-item
                                    v-for="(role, index) in paginatedPermissionList"
                                    :role="role"
                                    v-bind:key="index"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('staff.no_roles')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentPermissionPage"
                            :total-rows="getUserRolesFiltered.length"
                            :per-page="perPermissionPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                </div>
                <div class="card" v-show="activeTab == 3">
                    <div class="card-body">
                        <b-list-group-item class="flex-column align-items-start">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <!-- Avatar -->
                                    <b-avatar
                                        square
                                        class="avatar avatar-lg avatar-img rounded"
                                        src="/images/danalock-icon.png"
                                    ></b-avatar>
                                </div>
                                <div class="col ml-n2">
                                    <h4 class="mb-1 name">Danalock</h4>
                                </div>
                                <div class="col-auto">
                                    <!-- <b-badge
                                        v-if="danalockConnected"
                                        class="bg-success-soft mt-n1"
                                    >Connected</b-badge> -->
                                    <b-badge
                                        v-if="danalockConnected"
                                        class="bg-success-soft mt-n1"
                                    >{{ $t("providers.danalock_connected") }}</b-badge>
                                    <b-badge
                                        v-else
                                        class="bg-warning-soft mt-n1"
                                    >{{ $t("providers.danalock_not_connected") }}</b-badge>
                                </div>
                                <div class="col-auto" v-if="!danalockConnected">
                                    <!-- Dropdown -->
                                    <b-dropdown
                                        variant="outline"
                                        toggle-class="dropdown-ellipses dropdown-toggle"
                                        no-caret
                                        right
                                        toggle-tag="a"
                                    >
                                        <template v-slot:button-content>
                                           <!--  <i class="fe fe-more-vertical"></i> -->
                                           <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                        </template>
                                        <b-dropdown-item
                                            class="text-left text-truncate"
                                            @click="danalockLogin"
                                        >{{ $t("buttons.connect") }}</b-dropdown-item
                                        >
                                    </b-dropdown>
                                </div>
                            </div>
                            <!-- / .row -->
                        </b-list-group-item>
                    </div>
                </div>
                <div class="card" v-show="activeTab == 4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <!-- Card -->
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <!-- Title -->
                                                <h6 class="text-uppercase text-muted mb-2">
                                                    Current plan
                                                </h6>

                                                <!-- Heading -->
                                                <span class="h2 mb-0"> Basic - $29/mo </span>
                                            </div>
                                            <div class="col-auto">
                                                <!-- Icon -->
                                                <!-- <router-link
                                                                        class="btn btn-sm btn-primary"
                                                                        :to="{ name: 'pricing' }"
                                                                        >Upgrade</router-link
                                                                    > -->
                                            </div>
                                        </div>
                                        <!-- / .row -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <!-- Title -->
                                        <h4 class="card-header-title">Payment methods</h4>
                                    </div>
                                    <div class="col-auto">
                                        <!-- Button -->
                                        <a class="btn btn-sm btn-primary" href="#!"> Add method </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <!-- List group -->
                                <div class="list-group list-group-flush my-n3">
                                    <div class="list-group-item" style="overflow: hidden;">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <!-- Image -->
                                                <img
                                                    class="img-fluid"
                                                    src="img/payment/visa.svg"
                                                    alt="..."
                                                    style="max-width: 38px"
                                                />
                                            </div>
                                            <div class="col ml-n2">
                                                <!-- Heading -->
                                                <h4 class="mb-1">Visa **** **** **** 1234</h4>

                                                <!-- Text -->
                                                <small class="text-muted"> Expires 3/2024 </small>
                                            </div>
                                            <div class="col-auto mr-n3">
                                                <!-- Badge -->
                                                <span class="badge badge-light"> Default </span>
                                            </div>
                                            <div class="col-auto">
                                                <!-- Dropdown -->
                                                <div class="dropdown">
                                                    <a
                                                        class="dropdown-ellipses dropdown-toggle"
                                                        href="#"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <!-- <i class="fe fe-more-vertical"></i> -->
                                                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="#!"> Action </a>
                                                        <a class="dropdown-item" href="#!">
                                                            Another action
                                                        </a>
                                                        <a class="dropdown-item" href="#!">
                                                            Something else here
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- / .row -->
                                    </div>
                                    <div class="list-group-item" style="overflow: hidden;">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <!-- Image -->
                                                <img
                                                    class="img-fluid"
                                                    src="img/payment/mastercard.svg"
                                                    alt="..."
                                                    style="max-width: 38px"
                                                />
                                            </div>
                                            <div class="col ml-n2">
                                                <!-- Heading -->
                                                <h4 class="mb-1">Mastercard **** **** **** 1234</h4>

                                                <!-- Text -->
                                                <small class="text-muted"> Expires 3/2024 </small>
                                            </div>
                                            <div class="col-auto">
                                                <!-- Dropdown -->
                                                <div class="dropdown">
                                                    <a
                                                        class="dropdown-ellipses dropdown-toggle"
                                                        href="#"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <!-- <i class="fe fe-more-vertical"></i> -->
                                                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a class="dropdown-item" href="#!"> Action </a>
                                                        <a class="dropdown-item" href="#!">
                                                            Another action
                                                        </a>
                                                        <a class="dropdown-item" href="#!">
                                                            Something else here
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- / .row -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <zs-company-delete-modal />
        <zs-image-upload-modal @imageUploaded="imageUploaded" />
        <zs-danalock-modal @connected="danalockConnected = true" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_PATCH, USER_GET, USER_GET_ROLES } from "@/store/types/user";
import { CHECK_DANALOCK } from "@/store/types/providers";
import {
    M_IMAGE_UPLOAD,
    M_COMPANY_FORM,
    M_DANALOCK_LOGIN,
} from "@/components/modals/types";
import Select2 from "@/components/common/Select2";
import VuePhoneNumberInput from "vue-phone-number-input";
import ImageUploadModal from "@/components/modals/ImageUpload";
import DanalockLogin from "@/components/modals/DanalockLogin";
import ListTransition from "@/components/common/ListTransition";
import UserRoleItem from "@/components/user/UserRoleItem";
import UserCompanyItem from "@/components/user/UserCompanyItem";
import EmptyList from "@/components/common/EmptyList";
import DeleteCompanyModal from "@/components/modals/DeleteCompany";

import { validationMixin } from "vuelidate";
import modalMixin from "@/mixins/modalMixin";

import { IMAGE_TYPE_USER } from "@/handlers/const";
import {
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_KEY_MANAGER,
    ROLE_DEVICE_INSTALLER,
} from "@/acl/roles";
import {
    COMPANY_PATCH,
    COMPANY_GET,
} from "@/store/types/companies";

import { parsePhoneNumberFromString } from "libphonenumber-js";

import {
    required,
    email,
    minLength,
    maxLength,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin, modalMixin],
    data: function () {
        return {
            // user: {},
            user: [],
            validPhone: false,
            fullPhone: null,
            submitted: false,
            activeTab: 0,
            showRolesLoader: true,
            danalockConnected: false,
            avatar: null,
            saving: false,
            saved: false,
            filter: "",
            filterCompany: "",
            filterPermission: "",
            company:[],
            perPage: 20,
            currentPage: 1,
            perPermissionPage: 20,
            currentPermissionPage: 1,
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
        };
    },
    validations: {
        user: {
            FirstName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            LastName: {
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            PhoneNumber: {
                required,
            },
            Email: {
                required,
                email,
            },
        },
    },
    computed: {
        ...mapGetters(["getUser", "getUserRoles", "getUserCompanies", "getActiveCompany"]),
        getUserRolesFiltered() {
            return this.getUserRoles
                .map((item)=>item)
                .filter((item) =>
                    ![ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY].includes(item.Name))
                /* .filter((item) => item.Name.toLowerCase().includes(this.filterPermission.toLowerCase()))
                .map((item)=>{
                    if([ROLE_COMPANY_OWNER, ROLE_COMPANY_MANAGER, ROLE_LOCATION_MANAGER, ROLE_KEY_MANAGER, ROLE_DEVICE_INSTALLER].includes(item.Name)){
                        // item.Name.toLowerCase().includes(this.filterPermission.toLowerCase())
                        console.log("vcsd:"+item.Name+", and "+this.filterPermission)
                        if(item.Name.includes(this.filterPermission)){
                            return item
                        }else{
                            return
                        }
                    }
                }); */
        },
        paginatedPermissionList() {
            return this.getUserRolesFiltered.slice(
                (this.currentPermissionPage - 1) * this.perPermissionPage,
                this.currentPermissionPage * this.perPermissionPage
            );
        },
        userCompanies() {
            return this.getUserCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filterCompany.toLowerCase())
            )
            .sort((i1, i2) => {
                return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            });
        },
        paginatedList() {
            return this.userCompanies.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
    },
    created() {
        this.$store.dispatch(USER_GET).then((response) => {
            this.user = response;
            this.user.countryCode = parsePhoneNumberFromString(
                this.user.PhoneNumber
            ).country;
            this.user.PhoneNumber = parsePhoneNumberFromString(
                this.user.PhoneNumber
            ).nationalNumber;

            this.$store.dispatch(CHECK_DANALOCK, this.user.Id).then((response) => {
                this.danalockConnected = response;
            });
        });

        this.$store
            .dispatch(USER_GET_ROLES)
            .then(() => (this.showRolesLoader = false));

    },
    methods: {
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.user[name];
            return $dirty && this.submitted ? !$error : null;
        },
        updateProfile() {
            this.submitted = true;
            this.$v.user.$touch();
            if (this.$v.user.$anyError) {
                return;
            }
            let payload = {
                ...this.user,
                ...{
                    PhoneNumber: this.fullPhone.formattedNumber,
                    Name: this.user.FirstName,
                },
            };
            this.$store
                .dispatch(USER_PATCH, payload)
                .then((response) => {
                    // this.$store.dispatch(USER_GET)
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        userImage() {
            this.showModal(
                {
                    image: {
                        url: this.user.MediumImageUrl || '/images/person-big_MyZesec_140dp.png',
                        type: IMAGE_TYPE_USER,
                    },
                },
                M_IMAGE_UPLOAD
            );
            this.saved = false
        },
        imageUploaded(response) {
            this.submitted = true;
            this.$v.user.$touch();
            if (this.$v.user.$anyError) {
                return;
            }

            let payload = {
                ...this.user,
                ...{ ImageId: response.Id },
            };
            if(response.Name.includes("company_avatar")){
                    let cid = this.$session.get("companyId")
                    this.$store.dispatch(COMPANY_GET, cid).then((responseCompany) => {
                        this.company = responseCompany;
                        this.$store
                        .dispatch(COMPANY_PATCH, {
                        ...this.company,
                        ...{ ImageId: response.Id },
                        })
                        .then(() => {
                            this.$store.dispatch(COMPANY_GET, this.$session.get("companyId"));
                        })
                        .catch((error) => {});
                    });

            }else{
                this.$store
                .dispatch(USER_PATCH, payload)
                .then((response) => {
                    this.user = response;
                    // this.saving = true
                    // setTimeout(() => this.savedAvatar(), 1000)
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
                // this.saving = false
                // this.saved = true
            }

        },
        createCompany() {
            this.showModal(null, M_COMPANY_FORM);
        },
        danalockLogin() {
            this.showModal({ user: this.user }, M_DANALOCK_LOGIN);
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
        "zs-image-upload-modal": ImageUploadModal,
        "zs-list-transition": ListTransition,
        UserRoleItem,
        UserCompanyItem,
        EmptyList,
        "zs-danalock-modal": DanalockLogin,
        "zs-company-delete-modal": DeleteCompanyModal,
        Select2,
    },
};
</script>
<style scoped>
.badge.bg-success-soft {
    color: #00d97e;
}
.badge.bg-warning-soft {
    color: #e63857;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
