var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group-item',[_c('div',{staticClass:"row align-items-center",staticStyle:{"height":"100px"}},[_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                    name: 'company',
                    params: {
                        companyId: _vm.company.Id,
                    },
                }}},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.company.SmallImageUrl ||
                        '/images/zesec-placeholder.png'}})])],1),_c('div',{staticClass:"col ml-n2"},[_c('h4',{staticClass:"mb-1 name"},[_c('router-link',{attrs:{"to":{
                        name: 'company',
                        params: {
                            companyId: _vm.company.Id,
                        },
                    }}},[_vm._v(_vm._s(_vm.company.Name))])],1)]),(_vm.settingIcon)?_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{attrs:{"variant":"outline","toggle-class":"dropdown-ellipses dropdown-toggle","no-caret":"","right":"","toggle-tag":"a"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticStyle:{"width":"19px","height":"19px"},attrs:{"src":'/images/settings_grey_32x32dp.png'}})]},proxy:true}],null,false,2784308510)},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":_vm.editCompany}},[_vm._v(_vm._s(_vm.$t("buttons.edit")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",attrs:{"disabled":_vm.userRole == 'CompanyManager'},on:{"click":function($event){return _vm.deleteCompany(_vm.company.Id)}}},[_vm._v(_vm._s(_vm.$t("buttons.delete")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":function($event){return _vm.companyImage(_vm.company)}}},[_vm._v(_vm._s(_vm.$t("companies.image")))])],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }